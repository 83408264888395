<template>
  <section class="page-container">
    <div class="flex-col header text-left fw-500  color-dark">
      <span class="lh-150 fs-26">Racing Max</span>
      <span class="lh-150 fs-42 ">鴻朗保險</span>
    </div>
    <div class="flex-sb-center content">
      <div class="text-left flex-col">
        <div class="flex-col color-dark">
          <span class="fs-22 lh-150">Motor Cycle Insurance Application</span>
          <span class="fs-32 lh-150">電單車保險投保</span>
        </div>
        <span class="mg-t-32 z-index-top">
          <v-button @click="handleStart">Start 開始</v-button>
        </span>
      </div>
      <img
        class="img__confrim"
        src="../assets/images/index/confirm.png"
        alt="">
    </div>
    <div class="flex img-flex">
      <img
        class="img__1"
        src="../assets/images/index/vehicle_1.png"
        alt="">
      <img
        class="img__2"
        src="../assets/images/index/vehicle_2.png"
        alt="">
      <img
        class="img__3"
        src="../assets/images/index/vehicle_3.png"
        alt="">
      <img
        class="img__square3"
        src="../assets/images/index/square_3.png"
        alt="">
      <img
        class="img__square2"
        src="../assets/images/index/square_2.png"
        alt="">
      <img
        class="img__square1"
        src="../assets/images/index/square_1.png"
        alt="">
    </div>
  </section>
</template>

<script>
import MixinAllOptions from '@/mixins/all-options'

export default {
  name: 'index',
  mixins: [MixinAllOptions],
  methods:{
    handleStart(){
      this.$router.push('/quoting/policy/details')
    }
  }
}
</script>

<style scoped lang="scss">
  .page-container{
    height: 100vh;
    position: relative;
    max-width: 856px;
    margin: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    .header{
      padding:38px 0 0 32px;
    }
    .content{
      padding: 0px 48px 0px 32px;
      //transform: translateY(100%);
      //position: relative;
      //z-index: 999;
      flex:2;
      @media screen and (max-width: 768px) {
        //transform: translateY(20%);
        img{
          width: 169px;
        }
      }
      //@media screen and (min-height:825px ) and (max-height:1000px ){
      //  transform: translateY(25%);
      //}
      //@media screen and (max-height:824px ){
      //  transform: translateY(20%);
      //}
    }
    .img-flex{
      flex: 1;
      .img{
        &__1{
          position: absolute;
          bottom:0;
          //height: 369.34px;
          width: 49%;
        }
        &__2{
          position: absolute;
          left: 12.96%;
          bottom: -0.08%;
          width: 65%;
          //height: 429px;

        }
        &__3{
          position: absolute;
          //height: 369.34px;
          right: 0;
          bottom: 5px;
          //width: 49%;
          width: 58%;
        }
        &__square1{
          position: absolute;
          right: 0;
          bottom:0;
          width: 70%;
        }
        &__square2{
          position: absolute;
          right: 0;
          bottom: 0;
          width: 61%;
        }
        &__square3{
          position: absolute;
          right:0;
          bottom: 0;
          width: 50%;
        }
      }
    }
  }
  .z-index-top{
    z-index: 88;
  }
</style>
